import { Csku, Loading, Theme, themeOptions, Typography } from "@commonsku/styles";
import ErrorBoundary from "../components/ErrorBoundary";
import Header from "./Header";
import MainSection from "./MainSection";
import React from "react";

import { GlobalStyle } from "./DashboardApp";
import { useSelector } from "react-redux";
import withReducers from "../store/withReducers";
import { reducers } from "../store/configureManageDistributorApp";
import useCompanyGeneralDataById from "../hooks/useCompanyGeneralDataById";
import { ToastContainer } from "react-toastify";
import '../../scss/css/react-toastify.css';
import ManageDistributorStatus from "../components/ManageDistributorStatus";

const ManageDistributorApp = () => {
  const distributorId = useSelector(state => (state as any).entities.distributor_id);
  const {company: distributor, saveFeature, loading, save } = useCompanyGeneralDataById(distributorId);

  const content = loading === 'pending' ? <Loading mt={200} /> :
    <>
      <Typography.H1>{distributor.tenant_name}</Typography.H1>
      <div style={{display: 'flex'}}>
        <div style={{flexBasis: '50%'}}>
          <ManageDistributorStatus
            distributor={distributor}
            onSave={saveFeature}
            onChurn={save}
          />
        </div>
      </div>
    </>

  return (
    <Theme theme={themeOptions}>
      <GlobalStyle />
      <ErrorBoundary>
        <Header />
        <MainSection>
          <Csku as={"div"} className="main-content">
            {content}
            <ToastContainer
                autoClose={3000}
                hideProgressBar={true}
            />
          </Csku>
        </MainSection>
      </ErrorBoundary>
    </Theme>
  );

};

export default withReducers(ManageDistributorApp, reducers);
