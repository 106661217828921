import { Button, Col, LabeledCheckbox, LabeledSelect, Row, Typography } from "@commonsku/styles";
import React, { useState } from "react";
import useFeatures from "../hooks/useFeatures";
import withReducers from "../store/withReducers";
import { reducers } from "../store/configureManageDistributorApp";
import { paymentModelOptions } from "./dashboard";
import Features from "../constants/Features";
import { toast } from 'react-toastify';
import moment from "moment";
import { isPast } from "../utils";

type ManageDistributorStatusProps = {
  distributor: {
    feature_id: string,
    payment_model: string,
    churning: string | null,
    renewal_date: string,
    migration_due_date: string,
  };

  onSave: (
    params: {
      paymentModel: string,
      featureId: string,
      applyAdvancedCoupon: boolean,
    }
  ) => Promise<void>;

  onChurn: (
    params: {
      churning: number
    }
  ) => Promise<void>;
};

const ManageDistributorStatus = ({
  distributor,
  onSave,
  onChurn
}: ManageDistributorStatusProps) => {
  const [featureId, setFeatureId] = useState<string>(distributor.feature_id);
  const [applyAdvancedCoupon, setApplyAdvancedCoupon] = useState<boolean>(false);
  const [features] = useFeatures('DISTRIBUTOR');

  const featureDropdownItems = features.filter((f) => ['SOCIAL', 'FULL', 'ESSENTIAL', 'ADVANCED', 'ENTERPRISE'].includes(f.feature_name))
    .map((f) => {
      return {
        label: f.feature_description,
        value: f.feature_id
      }
    });

  const confirmAndSave = () => {
    if (confirm("Are you sure? You're about to update Stripe's Subscription")) {
      onSave({ featureId, paymentModel: distributor.payment_model, applyAdvancedCoupon });
    }
  };

  const confirmAndChurn = () => {
      if (confirm("Are you sure?")) {
        onChurn({ churning: distributor.churning ? 0 : 1 });
      }
    };

  const checkAndSet = ({ setToFeatureId }: {
    setToFeatureId: string,
  }) => {
    if (setToFeatureId === featureId) {
      return;
    }

    if (distributor.churning) {
      toast.error('Cannot change Feature level on a churning Distributor');
      setFeatureId(distributor.feature_id);
      return;
    }

    if (setToFeatureId === Features.DISTRIBUTOR_SOCIAL_FEATURE_ID) {
      toast.error('Cannot downgrade to Social Feature');
      setFeatureId(distributor.feature_id);
      return;
    }

    if (featureId === Features.DISTRIBUTOR_FULL_FEATURE_ID) {
      toast.error('Cannot migrate manually from Full Feature');
      setFeatureId(distributor.feature_id);
      return;
    }

    if (distributor.feature_id !== Features.DISTRIBUTOR_FULL_FEATURE_ID && setToFeatureId === Features.DISTRIBUTOR_FULL_FEATURE_ID) {
      toast.error('Cannot downgrade to Full Feature');
      setFeatureId(distributor.feature_id);
      return;
    }

    if (distributor.feature_id === Features.DISTRIBUTOR_ADVANCED_FEATURE_ID && setToFeatureId === Features.DISTRIBUTOR_ESSENTIAL_FEATURE_ID ||
      distributor.feature_id === Features.DISTRIBUTOR_ENTERPRISE_FEATURE_ID && [Features.DISTRIBUTOR_ESSENTIAL_FEATURE_ID, Features.DISTRIBUTOR_ADVANCED_FEATURE_ID].includes(setToFeatureId)
    ) {
      toast.error('Cannot downgrade Feature plan prior to contract renewal');
      setFeatureId(distributor.feature_id);
      return;
    }

    setFeatureId(setToFeatureId);
  }

  const now = new Date();
  const pastMigrationDueDate: boolean = !distributor.migration_due_date ? true : isPast(distributor.migration_due_date);
  const nextAction = distributor.churning ? 'Churning' : (
    pastMigrationDueDate ? 'Renewing' : 'Migrating'
  );

  const nextActionDate = distributor.churning ?? (
    pastMigrationDueDate ? distributor.renewal_date : distributor.migration_due_date
  );

  return (
    <>
      <Typography.H2>{nextAction} on {moment(nextActionDate).format("MMM [1st] YYYY")}</Typography.H2>
      <div style={{marginBottom: 10}}>
        <LabeledSelect
          label="Feature"
          name="featureId"
          value={featureDropdownItems.find((f) => f.value == featureId)}
          options={featureDropdownItems}
          onChange={(e) => checkAndSet({ setToFeatureId: e.value })}
          isClearable={false}
        />

        {featureId === Features.DISTRIBUTOR_ADVANCED_FEATURE_ID && distributor.feature_id === Features.DISTRIBUTOR_ESSENTIAL_FEATURE_ID &&
          <LabeledCheckbox
            label={'Apply Advanced Transition Coupon'}
            checked={applyAdvancedCoupon}
            onChange={() => setApplyAdvancedCoupon(!applyAdvancedCoupon)}
          />
        }
      </div>

      <div style={{marginBottom: 40}}>
        <LabeledSelect
          label="Payment model"
          name="paymentModel"
          value={paymentModelOptions.find((m) => m.value == distributor.payment_model)}
          options={paymentModelOptions}
          isDisabled={true}
          isClearable={false}
        />
      </div>

      <div>
        <Button
          disabled={!!distributor.churning}
          onClick={() => {
            confirmAndSave()
          }}
        >Save</Button>

        <Button ml={40}
          cta={true}
          onClick={() => {
            confirmAndChurn()
          }}
        >{distributor.churning ? 'Unset' : 'Set'} Churning flag</Button>
      </div>
    </>
  );

};

export default withReducers(ManageDistributorStatus, reducers);
